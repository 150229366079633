
.app {
  &-content {
    padding-left: 32px;
    padding-right: 32px;
  }

  .indent {
    padding-left: 12px;
  }

}

// Adjust settings for small devices
@media only screen and (max-width: 720px) {
  .app-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
